import React, { useContext, useEffect, useRef, useState } from "react"
import tw from "twin.macro"
import { Column, MenuItem } from "../../molecules/HeaderNavigation"
import { ImageLink } from "../../molecules/ImageLink"
import Icon from "../Icon"
import { NavList } from "../NavList"
import { DropdownProps, ItemProps, NavMenuProps, Section } from "./NavMenu.d"
import { Find } from "../../molecules/Find"
import { FamilySeriesTabs } from "../../molecules/FamilySeriesTabs"
import { LanguageContext } from "../../../contexts/Language"
import { SearchGlobalMobile } from "../../molecules/SearchGlobalMobile"
import { Banner } from "../../molecules/Banner"
import { HeaderContext } from "../../../contexts/Header"
import useTealiumEvent from "../../../hooks/Tealium/useTealiumEvent"
import { navigate } from "gatsby"
import { Link } from "../Link"
import { Image } from "../Image"
import { ThreeArrowAnimation } from "../../molecules/ThreeArrowAnimation"

/**
 *
 * @author Tyler, Stu
 * @summary - Header navigation menu that accepts dropdown items.
 *
 * @param {Object} items - Navigation items
 * @param {boolean} toggleMenu - Controls mobile menu open/close state
 * @param {(arg0: boolean) => void} setToggleMenu - Controls mobile menu open/close state
 * @param {boolean} mobileView - True if screen width is less than 1024px, read from DOM
 * @param remainingProps
 * @returns <NavMenu />
 *
 */

const NavMenu: React.FC<NavMenuProps> = ({
  items,
  toggleMenu,
  setToggleMenu,
  mobileView,
  open,
  setOpen,
  ...remainingProps
}) => {
  const { _ } = useContext(LanguageContext)

  // When menu is closed, or screen is sized up to desktop view while mobile
  // menu is opened, the menu and all menu items that are open are reset
  useEffect(() => {
    setOpen(null)
    setToggleMenu(false)
  }, [mobileView])

  useEffect(() => {
    setOpen(null)
  }, [toggleMenu])
  useEffect(() => {
    if (toggleMenu) {
      document.body.style.overflow = "hidden"
    } else {
      document.body.style.overflow = "auto"
    }
  }, [toggleMenu])

  // Tealium
  const { trackTealEvent } = useTealiumEvent()
  const [prevIndex, setPrevIndex] = useState(0)
  return (
    <div
      css={[
        tw`flex flex-col items-center relative bg-white h-0 transition-all duration-300 overflow-hidden scrollbar-hide`,
        tw`lg:(h-[70px] mt-0 flex-row static)`,
        toggleMenu &&
          "height: calc(100vh - 70px); box-sizing: border-box; z-index: 41; overflow-y: auto;",
      ]}
      {...remainingProps}
    >
      {/* Mobile Search Bar. The Header Search Bar lives in HeaderNavigation component */}
      {/*{mobileView && <SearchGlobalMobile mobileView={mobileView} />}*/}
      {items?.map((item: MenuItem, index: number) => (
        <Item
          label={item?.title}
          titleLink={item?.titleLink}
          index={index}
          open={open}
          setOpen={setOpen}
          section={item.title}
          onClick={() => {
            open == null || index != prevIndex
              ? trackTealEvent({
                  tealium_event: "nav_click",
                  click_text: `top nav:${item?.title}`,
                })
              : null
            setPrevIndex(index)
          }}
        >
          {item?.sections?.map((section: Section) => {
            switch (section?._type) {
              case "vehicles":
                return (
                  <FamilySeriesTabs
                    familyRef={section?.familyRef}
                    comingSoon={item.sections.find(
                      section => section._type === "comingSoonSection",
                    )}
                    mobileView={mobileView}
                  />
                )
              case "featuredAccessories":
                return (
                  <>
                    <div
                      css={[
                        tw`mt-6 max-w-desktop text-left col-span-3 align-top mx-auto md:(pl-10) lg:(col-span-2 mb-6 pl-0 inline-flex)`,
                        (item?.title?.toLowerCase() === "toyota accessories" ||
                          item?.title?.toLowerCase() === "accesorios toyota") &&
                          tw`lg:(inline-flex col-auto)`,
                      ]}
                    >
                      <div css={[tw`lg:(inline-block mr-8)`]}>
                        <span
                          css={tw`text-gray-700 font-semibold uppercase block max-w-xl`}
                        >
                          {_("Featured Accessories")}
                        </span>

                        <div css={[tw`block`, tw`lg:(inline-block)`]}>
                          {section?.featuredAccysRef?.map(
                            (featuredAccy: any) => (
                              <Link
                                to={`/${featuredAccy?.route?.slug?.current}`}
                                target="_self"
                                onClick={() => {
                                  trackTealEvent({
                                    tealium_event: "nav_click",
                                    click_text: `top nav:${featuredAccy?.page?.title}`,
                                  })
                                  setOpen(null)
                                }}
                                analytics-id={`${featuredAccy?.page?.title?.trimEnd()}:top nav:Accessories`}
                                css={[tw`w-full`, tw`sm:(w-auto)`]}
                              >
                                <div
                                  className="group"
                                  css={[
                                    tw`flex justify-between items-center mt-2 relative z-0 bg-gray-500 rounded-lg`,
                                    tw`sm:(w-48)`,
                                    tw`md:(mr-2 focus-visible:(outline-red-50))`,
                                    tw`lg:(mr-2)`,
                                  ]}
                                >
                                  <div
                                    css={[
                                      tw`absolute top-0 left-0 bottom-0 right-0 opacity-60 rounded-lg z-10 group-hover:opacity-40`,
                                      `
                                z-index:10;
                                background: rgb(0,0,0);
                                background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(255,255,255,0) 58%);
                                  `,
                                    ]}
                                  ></div>
                                  {featuredAccy?.bgImage && (
                                    <Image
                                      imageData={featuredAccy?.bgImage.image}
                                      css={[
                                        tw`w-full h-48 rounded-lg object-cover lg:(w-48 h-48)`,
                                      ]}
                                    />
                                  )}

                                  <div
                                    css={[
                                      tw`font-semibold inline text-sm absolute bottom-2 left-3 text-white z-20 pr-6`,
                                      tw`lg:(pr-2)`,
                                    ]}
                                  >
                                    {_(`${featuredAccy?.title}`)}
                                    <span
                                      css={[
                                        tw`inline absolute bottom-[1.35rem]`,
                                      ]}
                                    >
                                      <ThreeArrowAnimation />
                                    </span>
                                  </div>
                                </div>
                              </Link>
                            ),
                          )}
                        </div>
                      </div>
                    </div>
                  </>
                )
              case "featuredLink":
                return (
                  <>
                    <div
                      css={[
                        tw`block align-top mt-6 text-left`,
                        tw`sm:(pl-10)`,
                        tw`lg:(block pl-24 mr-8)`,
                        tw`xl:(inline-block pl-0 mr-8)`,
                      ]}
                    >
                      <span
                        css={[
                          tw`text-gray-700 font-semibold uppercase mb-4 block`,
                        ]}
                      >
                        {section?.heading}
                      </span>
                      <Link
                        to={`/${section?.featuredLink[0]?.slug?.current}`}
                        target="_self"
                        css={[
                          tw`w-full h-48 bg-black rounded-lg overflow-hidden`,
                          tw`sm:(w-72)`,
                          tw`lg:(w-52)`,
                          tw`2xl:(w-72)`,
                        ]}
                        onClick={() => {
                          trackTealEvent({
                            tealium_event: "nav_click",
                            click_text: `top nav:${section?.title}`,
                          })
                          setOpen(null)
                        }}
                        analytics-id={`${section?.title?.trimEnd()}:top nav:Accessories`}
                      >
                        <span
                          css={[
                            tw`font-semibold inline text-sm absolute bottom-2 left-3 text-white z-20 pr-4`,
                          ]}
                        >
                          {section?.title}
                          <br />
                          <span css={[tw`font-book`]}>
                            {section?.description}
                          </span>
                        </span>
                        <Image
                          imageData={section?.image.image}
                          css={[tw`object-cover`]}
                        />
                      </Link>
                    </div>
                  </>
                )
              case "find":
                return (
                  !mobileView && (
                    <Find
                      type={section?.type}
                      analyticsId={"zip submit:top nav:"}
                    />
                  )
                )
              case "imageLink":
                return (
                  !mobileView && (
                    <ImageLink
                      image={section?.image}
                      link={section?.link}
                      mobileView={mobileView}
                    />
                  )
                )
              case "linkColumns":
                return (
                  <div
                    css={[
                      tw`block text-left col-auto pt-6 w-full gap-2`,
                      tw`sm:(inline-flex pl-10)`,
                      tw`lg:(pl-24)`,
                      tw`xl:(inline-flex pl-0 mt-6 mr-0 pt-6 flex-shrink-0 w-auto col-span-1)`,
                      item.title?.toLowerCase() === "accessories" ||
                        (item.title?.toLowerCase() === "accesorios" &&
                          tw`xl:(inline-flex text-left col-auto pt-6 w-auto)`),
                      // tw`xl:(flex justify-end)`,
                      //  mobileView && tw`col-span-3 w-full bg-white mt-0 pt-4 md:(col-span-1)`,
                    ]}
                  >
                    {section?.columns?.map((column: Column) => (
                      <NavList
                        key={column?._key}
                        header={column?.heading}
                        items={column?.items}
                        section={item?.title}
                      />
                    ))}
                  </div>
                )
            }
          })}
        </Item>
      ))}
      <div css={[tw`relative w-full pt-8 lg:(hidden)`]}>
        <Banner />
      </div>
    </div>
  )
}

/**
 *
 * @component
 * @param {number} index - Position of each item, used to control state
 * @param {string} label - Title text displayed in menu for each item
 * @param {number} open - Stores the index of the item that's currently opened, used to control state
 * @param {Function} setOpen - Sets open state
 * @param children
 * @param remainingProps
 * @returns <Item></Item>
 */

const Item: React.FC<ItemProps> = ({
  index,
  label,
  open,
  setOpen,
  children,
  section,
  onClick,
  titleLink,
  ...remainingProps
}) => {
  const isOpened = open === index

  return (
    <div
      css={[
        tw`inline-block w-full px-10`,
        tw`md:(px-10)`,
        tw`lg:(px-2)`,
        tw`xl:(w-auto px-4)`,
        // Remove blue highlight on click (chrome)
        "-webkit-tap-highlight-color: transparent;",
      ]}
      itemProp="ItemElement"
      onClick={onClick}
      {...remainingProps}
      analytics-id={`${label}:top nav:`}
    >
      {titleLink ? (
        <span css={[tw`flex items-center`]}>
          <Link
            animated
            animatedThin
            className="group"
            css={[
              tw`flex justify-between items-center pt-6 pb-2 w-full font-semibold cursor-pointer text-black border-b border-gray-200`,
              tw`hover:(text-red-400)`,
              tw`focus-visible:(outline-red-50 text-red-400)`,
              tw`lg:(leading-[40px] p-0 border-none)`,
            ]}
            to={`/${titleLink.slug.current.toLowerCase()}`}
          >
            <span
              css={[
                tw`whitespace-nowrap text-xl`,
                tw`md:(text-base)`,
                tw`lg:(text-base)`,
              ]}
            >
              {label}
            </span>
          </Link>
        </span>
      ) : (
        <span css={[tw`flex items-center`]}>
          <Link
            animated
            animatedThin
            className="group"
            css={[
              tw`flex justify-between items-center pt-6 pb-2 w-full font-semibold cursor-pointer text-black border-b border-gray-200`,
              tw`hover:(text-red-400)`,
              tw`focus-visible:(outline-red-50 text-red-400)`,
              tw`lg:(leading-[40px] p-0 border-none)`,
              isOpened && tw`text-red-400`,
            ]}
            aria-expanded={isOpened}
            /* If open already equals item's index (read: item is opened), the click closes that item */
            onClick={() => {
              isOpened ? setOpen(null) : setOpen(index)
            }}
          >
            <span
              css={[
                tw`whitespace-nowrap mr-2 text-xl`,
                tw`md:(text-base)`,
                tw`lg:(text-base)`,
              ]}
            >
              {label}
            </span>
            <Icon.Chevron
              color="red-400"
              direction="down"
              css={[
                tw`h-3 transition-all duration-300 mt-0.5 ml-1`,
                isOpened && tw`transform -rotate-180`,
              ]}
            />
          </Link>
        </span>
      )}

      {!titleLink && isOpened
        ? children && (
            <Dropdown show={isOpened} sectionTitle={section}>
              {children}
            </Dropdown>
          )
        : null}
    </div>
  )
}

/**
 *
 * @component
 * @param {boolean} show - Controls whether menu is opened or closed
 * @param children
 * @returns <Dropdown></Dropdown>
 */

const Dropdown: React.FC<DropdownProps> = ({
  show,
  children,
  sectionTitle,
}) => {
  const { setOpen } = useContext(HeaderContext)
  return (
    <>
      <div
        css={[
          tw` hidden`,
          show &&
            tw`lg:(block bg-gray-900 opacity-70 h-full w-full fixed top-[118px] left-0 z-40)`,
        ]}
        onClick={() => show && setOpen(null)}
      ></div>
      <nav
        css={[
          tw`block w-full text-center mx-auto justify-items-center bg-white max-h-0 transition-all ease-in-out duration-300`,
          tw`lg:(absolute top-full bg-white left-0 bg-gradient-to-b from-gray-100)`,
          show && tw`max-h-[1270px] z-50`,
          (sectionTitle?.toLowerCase() === "parts & service" ||
            sectionTitle?.toLowerCase() === "partes y servicio") &&
            show &&
            tw`xl:(grid justify-items-center w-full bg-white transition-all ease-in-out duration-300 grid-cols-[23% 42% 35%])`,
          tw`2xl:(grid-cols-[20% 45% 35%])`,
        ]}
        aria-label="secondary"
      >
        {children}
      </nav>
    </>
  )
}

export default NavMenu
